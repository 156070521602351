/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import { LocationType } from '@/models/App.model';
import Api from '@/services/api';
 
export default {

  async listByShoot(config: {params: { shootId: number }}) {
    return Api().get('/booking/list_by_shoot', config);
  },

  async listConfirmedBookingsByEquipment(config: {params: { shootId: number, equipmentId: number }}) {
    return Api().get('/booking/list_confirmed_by_equipment', config);
  },

  async listShootBookingsByEquipment(config: {params: { shootId: number, equipmentId: number }}) {
    return Api().get('/booking/list_shoot_by_equipment', config);
  },

  create(params: {
    shootId: number,
    equipmentId: number,
    locationTypes: LocationType[],
    startDate: string,
    endDate: string,
    travelDays: number,
    downDays: number,
    dailyRate: number, // TODO this needs to be currency so make sure it works
  }) {
    return Api().post('/booking/create', params);
  },

  update(params: {
    id: number,
    locationTypes: LocationType[],
    startDate: string,
    endDate: string,
    travelDays: number,
    downDays: number,
  }) {
    return Api().post('/booking/update', params);
  },

  delete(params: { id: number }) {
    return Api().post('/booking/delete', params);
  },

  confirm(params: { id: number }) {
    return Api().post('/booking/confirm', params);
  },

  respond(params: { id: number, response: boolean }) {
    return Api().post('/booking/response', params);
  },
};
