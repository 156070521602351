var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product",class:_vm.getItemClass()},[_c('router-link',{attrs:{"to":'/' + _vm.productPath}},[_c('div',{staticClass:"product__content",staticStyle:{"position":"relative","z-index":"2"}},[_c('div',{staticClass:"product__prefix-image py-2 px-4"},[_c('v-img',{attrs:{"height":"30","width":"100","src":_vm.getBrandLogoUrl()}})],1),_c('div',{staticClass:"product__image px-4"},[_c('v-img',{attrs:{"height":"250","contain":"","src":_vm.getEquipmentPhotoUrl()}}),(_vm.booking.failedGearCheck)?_c('div',{staticClass:"product__warning",staticStyle:{"text-align":"center","opacity":"0.9"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"product__warning-content",staticStyle:{"position":"relative","display":"inline-block","padding-left":"50px"}},on),[_c('div',{staticStyle:{"text-align":"center","display":"inline-block","position":"relative"},attrs:{"color":"fff"}},[_c('v-icon',{staticClass:"product__warning-icon",attrs:{"large":"","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.$store.getters['app/icons'].gearCheck)+" ")]),_vm._v(" Failed Gear Check on "),_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.booking.failedGearCheckAt))])],1)])]}}],null,false,1239744719)},[_c('span',[_vm._v(_vm._s(_vm.itemState === 'confirmed' ? 'You are unable to confirm this item.' : 'This item has failed gear check.')+" You can either wait until it has been fixed or delete it and find an alternative item for this shoot.")])])],1):_vm._e()],1),_c('v-card-text',{staticClass:" d-flex justify-content-between align-end"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"product__breadcrumb"},[_vm._v(_vm._s(_vm.booking.category)+" > "+_vm._s(_vm.booking.subCategory)+" ")]),_c('h6',{staticClass:"mb-0 grey--text text--darken-4"},[_vm._v(_vm._s(_vm.booking.model))]),(_vm.booking.startDate)?_c('div',[_c('p',{staticClass:"product__booking-date mt-2 mb-5",staticStyle:{"color":"#000"}},[_c('v-icon',[_vm._v(_vm._s(_vm.$store.getters['app/icons'].shootDate))]),_vm._v(" "+_vm._s(_vm.booking.startDate)+" "+_vm._s(_vm.booking.startDate !== _vm.booking.endDate ? ' - ' + _vm.booking.endDate : '')+" "+_vm._s(_vm.bookingDuration)+" ")],1),(_vm.locationTypes.length > 1)?_c('div',{staticClass:"mb-4"},_vm._l((_vm.locationTypes),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v(" mdi-"+_vm._s(item.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.locationType))])])}),1):_vm._e(),(_vm.locationTypes.length === 1)?_c('div',{staticClass:"mb-4"},[_c('v-icon',{attrs:{"inline-block":""}},[_vm._v(" mdi-"+_vm._s(_vm.locationTypes[0].icon)+" ")]),_c('p',{staticStyle:{"margin-bottom":"0","color":"#000","display":"inline-block","margin-left":"8px"}},[_vm._v(" "+_vm._s(_vm.locationTypes[0].locationType)+" ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"product__rate"},[_vm._v(_vm._s(_vm.$store.getters['app/formatCurrency'](_vm.booking.rate)))])])]),(_vm.getRemovalAvailable())?_c('div',{staticClass:"product__remove"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","color":"primary","elevation":2},on:{"click":function($event){$event.preventDefault();return _vm.onRemoveBookingClicked.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v(_vm._s(_vm.$store.getters['app/icons'].delete))])],1)]}}],null,false,3488183730)},[_c('span',[_vm._v("Delete")])])],1):_vm._e(),(_vm.itemState === 'notice')?_c('div',{class:'product__respond ' + (_vm.booking.failedGearCheck ? 'product__respond--shift' : '')},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","color":"green","elevation":2},on:{"click":function($event){$event.preventDefault();return _vm.emitRespond(true)}}},on),[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v(_vm._s(_vm.$store.getters['app/icons'].yes))])],1)]}}],null,false,637785549)},[_c('span',[_vm._v("Respond yes")])])],1):_vm._e(),(_vm.itemState === 'notice')?_c('div',{class:'product__respond product__respond--alt ' + (_vm.booking.failedGearCheck ? 'product__respond--shift' : '')},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","color":"red","elevation":2},on:{"click":function($event){$event.preventDefault();return _vm.emitRespond(false)}}},on),[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v(_vm._s(_vm.$store.getters['app/icons'].no))])],1)]}}],null,false,4204018048)},[_c('span',[_vm._v("Respond no")])])],1):_vm._e(),(_vm.itemState === 'responded' || (_vm.itemState === 'responded' && _vm.booking.failedGearCheck))?_c('div',{class:'product__respond product__respond--alt ' + (_vm.itemState === 'responded' && _vm.booking.failedGearCheck ? 'product__respond--shift' : '')},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.booking.failedGearCheck ? 'mr-1' : '',attrs:{"small":"","fab":"","color":"#ccc","elevation":2},on:{"click":function($event){$event.preventDefault();return _vm.emitRespond(!_vm.booking.confirmationResponse)}}},on),[_c('v-icon',{attrs:{"color":"#fff","size":"32"}},[_vm._v(_vm._s(_vm.$store.getters['app/icons'].bookingNotice))])],1)]}}],null,false,3806554953)},[_c('span',[_vm._v("Change response")])])],1):_vm._e(),((typeof _vm.booking.confirmationResponse !== typeof null) && (_vm.itemState === 'notice' || _vm.itemState === 'responded'))?_c('div',{staticClass:"product__response"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.booking.confirmationResponse ? !_vm.booking.failedGearCheck ? 'green' : 'red' : 'red',"large":""}},on),[_vm._v(" "+_vm._s(_vm.booking.confirmationResponse ? _vm.$store.getters['app/icons'].yes : _vm.$store.getters['app/icons'].no)+" ")])]}}],null,false,1489522097)},[_c('span',[_vm._v("Responded "+_vm._s(_vm.booking.confirmationResponse ? 'yes' : 'no'))])])],1):_vm._e(),(_vm.itemState === 'confirmed' && _vm.booking.bookingConfirmed === true)?_c('div',{staticClass:"product__booking"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":!_vm.booking.failedGearCheck ? '#6ac5f4' : 'red',"large":""}},on),[_vm._v(" "+_vm._s(_vm.$store.getters['app/icons'].confirmedBooking)+" ")])]}}],null,false,2245698480)},[_c('span',[_vm._v("Confirmed Booking")])])],1):_vm._e(),(_vm.itemState === 'notice')?_c('div',{staticClass:"product__booking"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":!_vm.booking.failedGearCheck ? 'accent' : 'red',"large":""}},on),[_vm._v(" "+_vm._s(_vm.$store.getters['app/icons'].bookingNotice)+" ")])]}}],null,false,3675143735)},[_c('span',[_vm._v("24 hour notice")])])],1):_vm._e(),(_vm.itemState === 'basic')?_c('div',{staticClass:"product__booking"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":!_vm.booking.failedGearCheck ? 'primary' : 'red',"large":""}},on),[_vm._v(" "+_vm._s(_vm.$store.getters['app/icons'].pencil)+" ")])]}}],null,false,1200245771)},[_c('span',[_vm._v("Pencil Booking")])])],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }