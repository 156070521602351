



































































































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch} from 'vue-property-decorator';

// --- Models ---
import { Booking, BookingLocationType } from '@/models/Booking.model';

// --- Third Party ---
import dayjs from 'dayjs';

@Component
export default class BookingCard extends Vue {
  @Prop(Object) readonly booking!: Booking;
  @Prop(String) readonly bookingDuration!: string;
  @Prop(Array) readonly locationTypes!: BookingLocationType[];
  @Prop(String) readonly productPath!: string;

  today: string = dayjs(Date()).format('dddd-D-MMMM-YYYY');

  // Used for determining booking state
  itemState: string = 'empty';

  // #region Functions to display shoot data on page load
    beforeMount() {
      this.itemState = this.getItemState();
    }
  // #endregion

  // #region Functions to monitor booking and update display accordingly
    // Wait for change to bookings prop, means it has loaded
    @Watch('booking', {
      immediate: true,
      deep: true,
    })
    change() {
      this.itemState = this.getItemState();
    }
  // #endregion
  
  // #region Functions to pass booking back to Shoot View when clicking on controls
    onEditBookingClicked() {
      this.$emit('click:edit', this.booking);        
    }

    onRemoveBookingClicked() {
      this.$emit('click:remove', this.booking);
    }
  // #endregion

  // #region Helper Function to determine state of booking and apply styling
    getItemState() {
      if (
        !this.booking.confirmationRequested && 
        !this.booking.chargeHalfRate
      ) {
        return 'basic';
      }

      if (
        this.booking.confirmationRequested && 
        typeof this.booking.confirmationResponse === typeof null && 
        typeof this.booking.bookingConfirmed === typeof null && 
        !this.booking.chargeHalfRate
      ) {
        return 'notice';
      }

      if (
        this.booking.confirmationRequested && 
        typeof this.booking.confirmationResponse !== typeof null && 
        !this.booking.bookingConfirmed && !this.booking.chargeHalfRate
      ) {
        return 'responded';
      }

      if (
        this.booking.bookingConfirmed && 
        typeof this.booking.bookingConfirmed !== typeof null && 
        !this.booking.chargeHalfRate
      ) {
        return 'confirmed';
      }

      return 'cancelled';
    }

    getItemClass() {
      return 'product--' + this.getItemState() + (this.booking.failedGearCheck ? ' product--failed' : '');
    }

    getRemovalAvailable() {
      const removalAvailable = (this.itemState !== 'notice' && this.itemState !== 'cancelled') ||  this.booking.failedGearCheck;

      // if booking is in the past & confirmed, remove is not available
      if (this.itemState === 'confirmed' && dayjs(this.booking.startDate).diff(dayjs(this.today)) <= 0) {
        return false;
      }

      return removalAvailable;
    }
  // #endregion

  // #region Function for respnding to booking
    emitRespond(value: boolean) {
      this.$emit('click:respond', {response: value, booking: this.booking});
    }
  // #endregion

  // #region Functions to display equipment data on page load
    getEquipmentPhotoUrl() {
      // first check if the photo comes from the Equipment Type
      // if not, get it from the equipment
      // there should never not be one of them

      if (this.booking.typeImageFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment_type/' + this.booking.typeImageFilename;
      } else if (this.booking.imageFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment/' + this.booking.imageFilename;
      } else {
        return '';
      }
    }

    getBrandLogoUrl() {
      if (this.booking.brandLogoFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'brand_logos/' + this.booking.brandLogoFilename;
      } else {
        return '';
      }
    }
  // #endregion
}
