/**
 *   This is a service for a specific store module.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import AppService from '@/services/app';
import Store from '@/store/index';

export default {

  resetTypes() {
    // reset store to empty
    Store.dispatch('shoots/setShootTypes', {
      shootTypes: [],
    });
  },

  async listTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['shoots/shootTypes'].length < 1) {
      await Api().get('/shoot/list_types')
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.resetTypes();
              return response.data.message;
            } else {
              Store.dispatch('shoots/setShootTypes', {
                shootTypes: response.data,
              });
              return '';
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            throw new Error('response: ' + JSON.stringify(response));
          }
        })
        .catch((error) => {
          this.resetTypes();
          if (error.response) {
            return error.response.statusText;
          } else {
            AppService.logSupportDebug('shoot.ts - listTypes - 45 - ' + error);
            return Store.getters['app/messages'].couldNotConnect;
          }
        });
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },
 
  resetStore() {
    // reset store to empty
    Store.dispatch('shoots/setShoots', {
      shoots: [],
    });
  },

  async listAll(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['shoots/shoots'].length < 1) {
      await Api().get('/shoot/list_all')
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.resetStore();
              return response.data.message;
            } else {
              Store.dispatch('shoots/setShoots', {
                shoots: response.data,
              });
              return '';
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            throw new Error('response: ' + JSON.stringify(response));
          }
        })
        .catch((error) => {
          this.resetStore();
          
          if (error.response) {
            return error.response.statusText;
          } else {
            AppService.logSupportDebug('shoot.ts - listAll - 88 - ' + error);
            return Store.getters['app/messages'].couldNotConnect;
          }
        }); 
      } else {
        // this data is ready to access in the Store
        return '';
      }
  },

  create(params: {
    name: string,
    shootTypeId: number,
    regionId: number,
    startDate: string,
    endDate: string,
  }) {
    return Api().post('/shoot/create', params);
  },

  update(params: {
    id: number,
    name: string,
    startDate: string,
    endDate: string,
  }) {
    return Api().post('/shoot/update', params);
  },

  async listBookingLocationTypesByShoot(config: {params: { shootId: number }}) {
    return Api().get('/shoot/list_booking_location_types', config);
  },

  async getById(config: {params: { id: number }}) {
    return Api().get('/shoot/get', config);
  },

  async downloadQuote(config: {params: { id: number, type: boolean }}) {
    return Api().get('/shoot/quote', config);
  },

  delete(params: { id: number }) {
    return Api().post('/shoot/delete', params);
  },

};
