




























































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// --- Models ---
import { Booking } from '@/models/Booking.model';

// --- Third Party imports ---
import dayjs from 'dayjs';

@Component
export default class CartOverview extends Vue {
  @Prop(Array) bookings!: Booking[];

  // Used to locally calculated different booking states & totals
  bookingsLoaded: boolean = false;
  confirmedEquipment: Booking[] = [];
  currentBookings: Booking[] = [];
  pencilledBookings: Booking[] = [];

  // Used to display both data tables for pencil & confirmed equipment
  estimatedTotal: number = 0.00;
  currentTotal: number = 0.00;
  tableHeaders: object[] = [
    {
      align: 'start',
      value: 'model',
      text: 'model',
    },
    {
      value: 'dates',
      text: 'dates',
    },
    {
      value: 'rate',
      text: 'rate',
    },
  ];

  // #region Functions used to display booking information
    // Wait for change to bookings prop, means it has loaded
    @Watch('bookings', {
      immediate: true,
      deep: true,
    })
    change() {
      // check if there's actually bookings
      if (this.bookings.length) {
        this.bookingsLoaded = true;
        this.currentBookings = this.bookings;
        this.pencilledBookings = this.currentBookings.filter((item) => typeof item.bookingConfirmed === typeof null);
        this.confirmedEquipment = this.currentBookings.filter((item) => typeof item.bookingConfirmed !== typeof null);
      }
    }

    getItemTotal(item: Booking) {
      const totalDays = this.$store.getters['app/totalBookingDays'](item.startDate, item.endDate);

      // equipment cost at full rate:
      //item.rate * (totalDays - item.travelDays - item.downDays)
      // travel cost:
      //(item.rate / 2) * item.travelDays

      const itemTotal = (item.rate * (totalDays - item.travelDays - item.downDays)) + ((item.rate / 2) * item.travelDays);
      return (itemTotal  / (item.chargeHalfRate ? 2 : 1));
    }

    getItemSubTotal(item: Booking) {
      const totalDays = this.$store.getters['app/totalBookingDays'](item.startDate, item.endDate);
      return item.rate * (totalDays - item.travelDays - item.downDays);
    }

    getItemTravelTotal(item: Booking) {
      return (item.rate / 2) * item.travelDays;
    }

    getItemConfirmedTotal(item: Booking) {
      if (typeof item.bookingConfirmed !== typeof null) {
        return this.getItemTotal(item);
      }
      return 0;
    }
  // #endregion
}
