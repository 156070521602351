














































































































































































































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
import BookingCard from '@/components/app/BookingCard.vue';
import CartOverview from '@/components/app/CartOverview.vue';
import ConfirmActionDialog from '@/components/app/ConfirmActionDialog.vue';

// --- Models ---
import { Booking, BookingLocationType } from '@/models/Booking.model';
import { Shoot, ShootLocationType } from '@/models/Shoot.model';

// --- Services ---
import AppService from '@/services/app';
import BookingService from '@/services/booking';
import ShootService from '@/services/shoot';

// --- Third Party imports ---
import Hashids from 'hashids';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

@Component({
  components: {
    CartOverview,
    ConfirmActionDialog,
    BookingCard,
  },
})
export default class ShootDetail extends Vue {
  @Prop(String) readonly id!: string;

  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);
  today: string = dayjs().format('YYYY-MM-DD');

  clickedItem: Shoot = {
    id: 0,
    name: '',
    shootTypeId: 0,
    shootType: '',
    regionId: 0,
    region: '',
    startDate: '',
    endDate: '',
    canDelete: false,
  };

  clickedBookingId: number = 0;

  allBookings: Booking[] = [];
  bookingsToDisplay: Booking[] = [];
  bookingsToConfirm: Booking[] = [];
  bookingLocationTypes: BookingLocationType[] = [];

  displayCart: boolean = false;

  // used for disabling other inline edit fields
  disableOthers: string = '';

  // used by Date Range Picker for Shoot Date
  datePicker: boolean = false;
  datePickerHeader: string = '';
  dateRangeFormatted: string = '';
  dateRange: string[] = [];

  confirmedBookingDates: string[] = [];
  pencilBookingDates: string[] = [];
  allowedMinDate: string = '';
  allowedMaxDate: string = '';
  showDatePickerSelect: boolean = false;

  rules = {
    required: (value: string) => !!value || '',
  };

  // used by Confirm Action Dialog
  confirmActionDelay: boolean = false;
  dialogContent: string = '';
  action: string = '';
  showConfirmActionDialog: boolean = false;
  confirmActionDialogAvailable: boolean = false;

  // used by Quote Download
  downloadQuoteDelay: boolean = false;

  // #region Functions to display shoot data on page load
    async beforeMount() {
      const id: string = this.hashids.decode(this.$route.params.id) + '';

      if (id && id.length > 0) {
        try {
          this.clickedItem.id = parseInt(id, 10);

          // Get Shoot detail by id
          await this.getShootById();

          if (this.clickedItem) {
            // get regions & shoot types if they haven't been fetched already
            await AppService.listRegions(false);
            await ShootService.listTypes(false);
            this.clickedItem.shootType = this.$store.getters['shoots/shootTypes'].find( (i: any) => i.id === this.clickedItem.shootTypeId).name;

            await this.listBookingLocationTypesByShoot();
            await this.listBookingsByShoot();
          } else {
            // kick  them out, how did they even get here?!
            this.$router.push({ name: 'My Shoots' });
          }
        } catch (error) {
          // kick  them out, we couldn't decode their id as an int
          this.$router.push({ name: 'My Shoots' });
        }
      } else {
        // kick  them out, how did they even get here?!
        this.$router.push({ name: 'My Shoots' });
      }
    }

    async getShootById() {
      try {
        const response = await ShootService.getById({ params: {id: this.clickedItem.id} });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            // kick  them out, we couldn't find their Shoot in the DB
            this.$router.push({ name: 'My Shoots' });
          } else {
            this.clickedItem = response.data;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_View.vue - getShootById - 393 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      }
    }

    async listBookingLocationTypesByShoot() {                 
      await ShootService.listBookingLocationTypesByShoot({ params: {shootId: this.clickedItem.id} })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.bookingLocationTypes = [];
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              this.bookingLocationTypes = response.data;
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('Shoot_View.vue - listBookingLocationTypesByShoot - 414 - ' + JSON.stringify(response));
          }
        });
    }

    async listBookingsByShoot() {
      await BookingService.listByShoot({ params: {shootId: this.clickedItem.id} })
        .then((response) => {
          if (response && response.data) {
            if (response.data.result && response.data.result === 'false') {
              this.allBookings = [];
              this.bookingsToDisplay = [];
              if (response.data.message !== 'No Data Found') {
                AppService.errorHandler(response.data.message);
              }
            } else {
              // all bookings will be used by the Cart Overview
              this.allBookings = response.data;
              this.bookingsToConfirm = this.allBookings.filter(
                (item: Booking) => {
                  return (typeof item.bookingConfirmed === typeof null) && !item.confirmationRequested && !item.failedGearCheck;
              });
              this.displayCart = true;

              // bookings to display will remove ones that were cancelled but need to still be charged for
              this.bookingsToDisplay = this.allBookings.filter((item: Booking) => !item.chargeHalfRate);
            }
          } else {
            // response is undefined or has no data field - SHOULD NEVER HAPPEN!
            AppService.logSupportDebug('Shoot_View.vue - listBookingsByShoot - 443 - ' + JSON.stringify(response));
          }
        });
    }

    getShootLocationTypes() {
      return this.bookingLocationTypes ? this.bookingLocationTypes.reduce((previousValue: ShootLocationType[], currentValue: BookingLocationType) => {
        if (previousValue.findIndex((item: ShootLocationType) => item.id === currentValue.locationTypeId) < 0) {
          // we don't have it in our new list yet, add it now!
          previousValue.push({ 
            id: currentValue.locationTypeId,
            icon: currentValue.icon,
            type: currentValue.locationType,
            equipmentCount: this.bookingLocationTypes.filter((item: BookingLocationType) => 
              item.locationTypeId === currentValue.locationTypeId).length,
          });
        } 
        return previousValue;
      }, []) : [];
    }

    goToEquipmentListing() {
      this.$router.push({ 
        name: 'Shoot Equipment Listing', 
        params: { 
          shootId: '' + this.hashids.encode(this.clickedItem.id),
        },
      }); 
    }

    getLocationTypeById(id: number) {
      return this.bookingLocationTypes.filter((locationType) => {
        return locationType.bookingId === id;
      });
    }

    getEquipmentPhotoUrl(item: Booking) {
      // first check if the photo comes from the Equipment Type
      // if not, get it from the equipment
      // there should never not be one of them

      if (item.typeImageFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment_type/' + item.typeImageFilename;
      } else if (item.imageFilename) {
        // files for test data are in a local folder so they don't conflict with LIVE data
        return 'https://media.eazigear.co.za/' + (process.env.VUE_APP_ISLIVE === 'YES' ? '' : 'local/') + 'equipment/' + item.imageFilename;
      } else {
        return '';
      }
    }
  // #endregion

  // #region Functions to Edit Shoot Dates
    getBookingDates() {
      // get events to show from confirmed bookings
      this.confirmedBookingDates = [];
      this.pencilBookingDates = [];
      for (const booking of this.bookingsToDisplay) {
        // add all the days between start date & end date to confirmedBookingDates
        const endDate = dayjs(booking.endDate);
        let currentDate = dayjs(booking.startDate);

        if (booking.bookingConfirmed) {
          
          while (currentDate.isSameOrBefore(endDate)) {
            this.confirmedBookingDates.push(currentDate.format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'day');
          }
        } else {
          while (currentDate.isSameOrBefore(endDate)) {
            this.pencilBookingDates.push(currentDate.format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'day');
          }
        }
      }

      // remove dates from pencil bookings if they already in confirmed list
      this.pencilBookingDates = this.pencilBookingDates.filter((date: string) => !this.confirmedBookingDates.includes(date));
    }

    getEventColourByBookingType(date: string) {
      return (this.confirmedBookingDates.includes(date) ? 'accent' : 'grey');
    }

    updateShootDates() {
      this.disableOthers = 'shootdates';
      this.datePickerHeader = 'Select Start Date';
      this.dateRangeFormatted = this.$store.getters['app/formattedDateRange'](this.clickedItem.startDate, this.clickedItem.endDate, false);
      this.getBookingDates();
    }

    cancelShootDates() {
      this.dateRange = [];
      this.disableOthers = '';
    }

    datePickerToggle(opened: boolean) {
      if (opened) {
        this.dateRange = [];
        this.datePickerHeader = 'Select Start Date';

        // check which start dates are allowed

        // set min & max Date range for Start Date selector
        const shootStartDate = dayjs(this.clickedItem.startDate);
        const today = dayjs().add(3, 'day');

        this.allowedMaxDate = '';

        if (shootStartDate.isAfter(today)) {
          this.allowedMinDate = today.format('YYYY-MM-DD');

          if (this.confirmedBookingDates.length > 0 || this.pencilBookingDates.length > 0) {
            // we have bookings - find the earliest Booking Date
            const newArray = this.confirmedBookingDates.concat(this.pencilBookingDates);
            newArray.sort();
            const maxStartDate = dayjs(newArray[0]);
            this.allowedMaxDate = maxStartDate.format('YYYY-MM-DD');
          }
        } else {
          this.allowedMinDate = shootStartDate.format('YYYY-MM-DD');

          if (this.confirmedBookingDates.length > 0 || this.pencilBookingDates.length > 0) {
            // we have bookings - find the earliest Booking Date
            const newArray = this.confirmedBookingDates.concat(this.pencilBookingDates);
            newArray.sort();
            const maxStartDate = dayjs(newArray[0]);
            this.allowedMaxDate = maxStartDate.format('YYYY-MM-DD');
            if (maxStartDate.isSameOrBefore(shootStartDate)) {
              // they can't change the shootDate
              this.allowedMaxDate = shootStartDate.format('YYYY-MM-DD');
            }
          }
        }
      }
    }

    triggerDatePickerInput(date: string) {
      // Check if daterange has start & end date, dateRange will contain only first date if no end date
      if (this.dateRange.length === 1) {
        // single date selection

        // reset allowed min & max dates
        const shootEndDate = dayjs(this.clickedItem.endDate);
        const today = dayjs().add(3, 'day');
        this.allowedMinDate = this.dateRange[0];
        this.allowedMaxDate = '';

        const allBookingsArray = this.confirmedBookingDates.concat(this.pencilBookingDates);
        if (allBookingsArray.length > 0) {
          // we have bookings - find the earliest Booking Date          
          allBookingsArray.reverse();
          const minEndDate = dayjs(allBookingsArray[0]);
          this.allowedMinDate = minEndDate.format('YYYY-MM-DD');

          
        }

        // check if single date selection is allowed
        if (allBookingsArray.length > 1 || (allBookingsArray.length === 1  && !(dayjs(this.dateRange[0]).isSame(dayjs(allBookingsArray[0]))))) {
          this.showDatePickerSelect = false;
          this.datePickerHeader = 'Select End Date';
        } else {
          this.showDatePickerSelect = true;
          this.datePickerHeader = this.$store.getters['app/formattedDateRange'](this.dateRange[0], this.dateRange[0], true); 
        }
      } else {
        this.showDatePickerSelect = false;

        // Update the custom header with the formatted date and total days for feedback to the user on
        // what is actually selected
        this.datePickerHeader = this.$store.getters['app/formattedDateRange'](this.dateRange[0], this.dateRange[1], true); 
        this.selectDateFromDatePicker();
      }
    }

    cancelDatePicker() {
      this.dateRange = [];
      this.datePicker = false;
      this.datePickerToggle(false);
    }

    selectDateFromDatePicker() {
      if (!this.dateRange[1]) {
        this.dateRange[1] = this.dateRange[0];
      }
      this.dateRangeFormatted = this.$store.getters['app/formattedDateRange'](this.dateRange[0], this.dateRange[1], false);
      this.datePicker = false;
      this.datePickerToggle(false);
    }

    async doUpdateShootDate() {
      try {
        const response = await ShootService.update({
          id: this.clickedItem.id,
          name: this.clickedItem.name,
          startDate: this.dateRange[0],
          endDate: this.dateRange[1],
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            await ShootService.listAll(true);
            AppService.successHandler(response.data.message);
            this.clickedItem.startDate = this.dateRange[0];
            this.clickedItem.endDate = this.dateRange[1];
            this.dateRange = [];
            this.disableOthers = '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_View.vue - doUpdateShoot - 741 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }
      }
    }
  // #endregion

  // #region Functions required to handle Booking Response
    updateBooking(value: boolean) {
      const currentBooking = this.bookingsToDisplay.find((item) => item.id === this.clickedBookingId);
      if (currentBooking) {
        currentBooking.confirmationResponse = value;
      }
    }
  // #endregion

  // #region Functions required for the Quote Download
    async downloadQuote(inflate: boolean) {
      try {
        this.downloadQuoteDelay = true;

        const response = await ShootService.downloadQuote({ params: {
          id: this.clickedItem.id,
          type: inflate,
        }});

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = 'https://media.eazigear.co.za/' + response.data.message;
            link.click();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_View.vue - downloadQuote - 667 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.downloadQuoteDelay = false;
      }
    }
  // #endregion

  // #region Functions required for the Confirm Action Dialog
    launchDeleteEquipmentItemDialog(item: Booking) {
      this.clickedBookingId = item.id;

      this.action = 'DELETE_BOOKING';   
      this.dialogContent = 'Your <span style="font-weight: 700">' + this.$store.getters['app/formattedDateRange'](item.startDate, item.endDate, true) + '</span>'
        + '<br/>booking for the ' + item.model
        + '<br/><img src="' + this.getEquipmentPhotoUrl(item) + '" style="width: 100px; height: auto; margin: auto;"/>'
        + '<br/></span> will be <span style="font-weight: 700;color: #C01212">removed</span> from your shoot.'
        + (dayjs(item.startDate).diff(this.today) <= 86400000 ? 
          '<br/><br/><span style="font-weight: 700;color: #C01212">You will incur a halfrate booking penalty fee if you cancel 1 day before your booking date</span>' : 
          '' )
        + '<br/><br/>Are you sure?';        

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    launchConfirmEquipmentDialog() {
      this.action = 'CONFIRM_EQUIPMENT';
      this.dialogContent = 'You are about to <span style="font-weight: 700;">issue 24hr notice</span> on ' + this.bookingsToConfirm.length 
        + ' piece' + (this.bookingsToConfirm.length > 1 ? 's' : '')
        + ' of Equipment.'
        + '<br/><br/>Are you sure?';

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    launchBookingResponseDialog(params: {response: boolean, booking: Booking}) {

      this.clickedBookingId = params.booking.id;
      
      if (params.response) {
        this.dialogContent = '<strong>Yes, I really do want this </strong><br/>';
        this.action = 'CONFIRM_BOOKING';
      } else {
        this.dialogContent = 'You can <span style="font-weight: 700;color: #C01212">release</span> my booking for this <br/>';
        this.action = 'RELEASE_BOOKING';
      }

      if (params.booking) {
        this.dialogContent += params.booking.model + '<br/>';
        this.dialogContent += '<img src="' + this.getEquipmentPhotoUrl(params.booking) + '" style="width: 100px; height: auto; margin: auto;"/><br/>';
        this.dialogContent += ' for ' + this.$store.getters['app/formattedDateRange'](params.booking.startDate, params.booking.endDate, true);
      }

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;

      setTimeout(() => {
        this.confirmActionDialogAvailable = false;
      }, 200);
    }

    ConfirmActionDialog_doConfirmed() {
      if (this.action === 'DELETE_BOOKING') {
        this.doDeleteBooking();
      } else if (this.action === 'CONFIRM_EQUIPMENT') {
        this.doConfirmEquipment();
      } else if (this.action === 'CONFIRM_BOOKING') {
        this.doUpdateBookingResponse(true);
      } else if (this.action === 'RELEASE_BOOKING') {
        this.doUpdateBookingResponse(false);
      }
    }

    async doDeleteBooking() {
      try {
        this.confirmActionDelay = true;

        const response = await BookingService.delete({ id: this.clickedBookingId });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            // completed, refresh bookings for this shoot?
            await this.listBookingLocationTypesByShoot();
            await this.listBookingsByShoot();
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_View.vue - doDeleteBooking - 701 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }

    async doConfirmEquipment() {
      try {
        this.confirmActionDelay = true;

        const response = await BookingService.confirm({ id: this.clickedItem.id }); // the shoot id

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            // completed, refresh bookings for this shoot to get updated states
            await this.listBookingsByShoot();
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_View.vue - doConfirmEquipment - 734 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }

    async doUpdateBookingResponse(value: boolean) {
      try {
        this.confirmActionDelay = true;

        const response = await BookingService.respond({ 
          id: this.clickedBookingId, // this should be the booking id
          response: value,
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            // Update booking values in the list to reflect DB
            this.updateBooking(value);
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('Shoot_View.vue - doUpdateBookingResponse - 770 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }
  // #endregion

}
